import {
    FETCH_BRAND_PRODUCTS
} from '../actions/types'

export default (state = {}, action) => {
    switch (action.type){
        case FETCH_BRAND_PRODUCTS:
            return { ...state, [action.payload.searchName]: action.payload.data }
        default:
            return state
    }
}
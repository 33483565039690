import React from 'react'
import {
    Section,
    Container,
    Row,
    Grid
} from '../../components/Grid'
import { reduxForm } from 'redux-form'
import Input from '../../components/Forms/Input'
import { validateEmail, validatePhone } from '../../helpers'
import { makeAnAppointmentForm } from '../../actions'
import { connect } from 'react-redux'

class MakeAnAppointment extends React.Component {

    state = {
        sending: false
    }

    onSubmit = formValues => {
        this.setState({
            sending: true
        })
        this.props.makeAnAppointmentForm(formValues, this.props.collection)
    }

    render(){
        return(
            <Section className="bc_form-section">
                
                <Container className="bc_small">
                    <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                        <Row>
                        
                            <Grid className="bc_form-controll">
                                <h2 className="themegold">Make An Appointment</h2>
                            </Grid>
                            <Grid className="bc_form-controll">
                                <Input name="fullname" type="text" label="Full Name" />
                            </Grid>
                            <Grid col="6" className="bc_form-controll">
                                <Input name="email" type="email" label="Email" />
                            </Grid>
                            <Grid col="6" className="bc_form-controll">
                                <Input type="text" label="Contact Number" name="phone" />
                            </Grid>
                            <Grid className="bc_form-controll">
                                <Input label="Write Your Message Here..." name="message" type="textarea" />
                            </Grid>
                            <Grid className="bc_display-flex bc_justifyContent-center">
                                {
                                    !this.state.sending && (
                                        <input className="bc_form-submit" type="submit" value="Submit" />
                                    )
                                }
                                {
                                    this.state.sending && (
                                        <p>Sending Message...</p>
                                    )
                                }
                            </Grid>
                        
                        </Row>
                    </form>
                </Container>
            </Section>
        )
    }
}

const validate = formValues => {
    const errors = {}

    if(!formValues.fullname){
        errors.fullname = "You must enter a first name"
    }

    if(!formValues.email){
        errors.email = "You must enter an email"
    } else if(formValues.email && !validateEmail(formValues.email)){
        errors.email = "You must enter a valid email"
    }

    if(!formValues.phone){
        errors.phone = "You must enter a phone number"
    } else if(formValues.phone && !validatePhone(formValues.phone)){
        errors.phone = "You must enter a valid phone number"
    }

    if(!formValues.message){
        errors.message = "You must type a message"
    }

    return errors
}

export default reduxForm({
    form: 'contactForm',
    validate
})(connect(null, {
    makeAnAppointmentForm
})(MakeAnAppointment))
import React from 'react'
import { Link } from 'react-router-dom'
import logoWhite from '../../assets/img/Bathroom-Collective-Logo-White.svg'
import logoCPandB from '../../assets/img/CP&B-Logo-White.svg'
import facebook from '../../assets/img/facebook-logo-button.svg'
import instagram from '../../assets/img/instagram.svg'
import pinterest from '../../assets/img/pinterest-logo-button.svg'
import {
    subscription
} from '../../actions'
import {connect} from 'react-redux'
import SubscriptionForm from '../../views/Forms/SubscriptionForm'

class Footer extends React.Component {

    onSubscribe = formValues => {
        this.props.subscription(formValues)
    }

    render() {
        return (
            <React.Fragment>
                <footer>
                    <div className="item">
                        <div className="img">
                            <img src={logoWhite} alt="Bathroom Collective Logo White" />
                        </div>
                        <a href="https://www.cpandb.co.za/" target="_blank" className="img" title="Go To CP&B Website"  rel="noopener noreferrer">
                            <p className="white"><small>Brought to you by</small></p>
                            <img src={logoCPandB} alt="CPandB Logo White" />
                        </a>
                    </div>
                    <div className="item">
                        <ul>
                            <li><Link to="/contact">Contact</Link></li>
                            {/* <li><Link to="/terms-and-conditions">Terms & Conditions</Link></li> */}
                            <li>Email: &nbsp;<a href="mailto:info@bathroomcollective.co.za&subject=Bathroom Collective Enquiry" title="Email Bathroom Collective">info@bathroomcollective.co.za</a></li>
                        </ul>
                    </div>
                    <div className="item">
                        <ul>
                            <li>Subcribe to our Newsletter</li>
                            <li>
                                {
                                    !this.props.subscribed && <SubscriptionForm onSubmit={this.onSubscribe} />
                                }
                                {
                                    this.props.subscribed && <>You have successfully subscribed to our newsletter!</>
                                }
                            </li>
                        </ul>
                    </div>
                    <div className="item">
                        <ul>
                            <li>
                                <a href="https://www.facebook.com/Bathroom-Collective-100911267955966/" target="_blank" title="View Facebook page"  rel="noopener noreferrer">
                                <img src={facebook} alt="Facebook" width="40" height="40" />
                                Facebook</a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/bathroom_collective/" target="_blank" title="View Instagram page"  rel="noopener noreferrer">
                                <img src={instagram} alt="Instagram" width="40" height="40" />
                                Instagram</a>
                            </li>
                            <li>
                                <a href="http://www.pinterest.com/bathroom_collective" target="_blank" title="View Pinterest page"  rel="noopener noreferrer">
                                <img src={pinterest} alt="Twitter" width="40" height="40" />
                                Pinterest</a>
                            </li>
                        </ul>
                    </div>
                    
                </footer>
                <div className="footer_bottom">
                    <p><strong>Bathroom Collective</strong> | All Rights Reserved | <strong><Link to="/terms-and-conditions" style={{color: 'white'}}>T&amp;C's</Link></strong> | Powered by <strong><a href="https://optimalonline.co.za" target="_blank"  rel="noopener noreferrer" title="View Optimal Online website" style={{color: 'white'}}>OPTIMAL ONLINE</a></strong></p>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        subscribed: state.subscriptionState.subscribed
    }
}

export default connect(mapStateToProps, {
    subscription
})(Footer)
import { 
    CONTACT_FORM_MESSAGE
} from '../actions/types'

const INITIAL_STATE = {
    sent: false
}

export default (state = INITIAL_STATE, action) => {    
    switch (action.type){
        case CONTACT_FORM_MESSAGE:
            return {
                ...state,
                sent: true
            }
        
        default:
            return state
    }
}
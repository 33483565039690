import _ from 'lodash'
import {
    FETCH_CATEGORIES_WITH_ONE_PRODUCT
} from '../actions/types'

export default (state = {}, action) => {
    switch (action.type){
        case FETCH_CATEGORIES_WITH_ONE_PRODUCT:
            return { ...state, ..._.mapKeys(action.payload, 'search_name') }
        default:
            return state
    }
}
import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { validateEmail } from '../../helpers'

class SubscriptionForm extends React.Component {

    state = {
        error: null
    }

    renderError = ({error, touched}) => {
		if(error && touched){
            this.setState({
                error
            })
		}
    }

    renderInput = ({input, label, meta}) => {
        this.renderError(meta)
        return (
            <>
                <div className="field">
                    <input {...input} type="email" autoComplete="off" placeholder={label} />
                </div>
            </>
        )
    }

    onSubmit = formValues => {
        this.props.onSubmit(formValues)
    }

    render(){
        
        return(
            <>
                <form className="ui form bc_footer-form" onSubmit={this.props.handleSubmit(this.onSubmit)}>
                    <Field name="email" component={this.renderInput} label="Enter Email" />
                    <button className="ui button" type="submit">Submit</button>
                </form>
                {
                    this.state.error && (
                        <div className="bc_form-error-msg">
                            {this.state.error}
                        </div>
                    )
                }
            </>
        )
    }
}

const validate = formValues => {
    const errors = {}

    if(!formValues.email){
        errors.email = "You must enter an email"
    } else if(formValues.email && !validateEmail(formValues.email)){
        errors.email = "You must enter a valid email"
    }

    return errors
}

export default reduxForm({
    form: 'subscriptionForm',
    validate
})(SubscriptionForm)
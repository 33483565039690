import {
    PASSWORD_FORGOT,
    PASSWORD_FORGOT_ERROR
} from '../actions/types'

export default (state = {}, action) => {
    switch(action.type){
        case PASSWORD_FORGOT:
            return {
                success: action.payload.message
            }
        case PASSWORD_FORGOT_ERROR:
            return {
                error: action.payload.message
            }
        default:
            return state
    }
}
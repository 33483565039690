import React from 'react'
import Carousel from '../../components/SlickSlider/Carousel'
import {
    Section,
    Container,
    Row,
    Grid
} from '../../components/Grid'
import SectionHeading from '../../components/Content/SectionHeading.js'
import { BASE_URL } from '../../paths'
import { Link } from 'react-router-dom'
import ImageScaleOnHover from '../../components/Animations/ImageScaleOnHover'

import LazyLoad from 'react-lazyload';

class ProductBrandCarousel extends React.Component {

    renderList(){
        return this.props.data.map((item, index) => {
            if(this.props.data.length > 3){
                return (
                    <div className="bc_item" key={index}>
                        <Link to={`/${this.props.searchName}/${item.category && item.category.search_name}`}>
                            <ImageScaleOnHover>
                                {
                                    index > 3 && (
                                        <LazyLoad>
                                            <figure>
                                                <img className="bc_image" src={`${BASE_URL}${item.image}`} alt="" className="bc_image" />
                                            </figure>
                                        </LazyLoad>
                                    )
                                }
                                {
                                    index <= 3 && (
                                        <figure>
                                            <img className="bc_image" src={`${BASE_URL}${item.image}`} alt=""  />
                                        </figure>
                                    )
                                }
                                
                            </ImageScaleOnHover>
                            
                            <div className="bc_bottom-title"><h4>{item.category && item.category.title}</h4></div>
                        </Link>                    
                    </div>
                )
            } else {
                return (
                    <Grid col="4" className="bc_brand-category-item" key={index}>
                        <div className="bc_item" key={index}>
                            <Link to={`/${this.props.searchName}/${item.category.search_name}`}>
                                <ImageScaleOnHover>
                                    <figure>
                                        <img className="bc_image" src={`${BASE_URL}${item.image}`} alt="" className="bc_image" />
                                    </figure>
                                </ImageScaleOnHover>
                                
                                <div className="bc_bottom-title"><h4>{item.category.title}</h4></div>
                            </Link>                    
                        </div>
                    </Grid> 
                )
            }
        })
    }

    render(){

        if(!this.props.data){
            return <></>
        }

        return(
            <Section className={`bc_slider-section bc_less-height ${this.props.className ? this.props.className : ''}`}>
                <Container>
                    <SectionHeading text={this.props.title} />
                    {
                        this.props.data.length > 3 && 
                        <Carousel>
                            {this.renderList()}
                        </Carousel>
                    }
                    {
                        this.props.data.length <= 3 && 
                        <Row>
                            {this.renderList()}
                        </Row>
                    }
                </Container>
            </Section>
        )
    }
}

export default ProductBrandCarousel
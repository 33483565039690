import React from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../../components/Nav/Nav'
import MakeAnEnquiry from '../Forms/MakeAnEnquiry'
import SmallHeading from '../../components/Content/SmallHeading'
import {
    Section,
    Container,
    Row,
    Grid
} from '../../components/Grid'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {
    fetchProduct
} from '../../actions'
import { BASE_URL } from '../../paths'
import OnScrollAnimation from '../../components/Animations/OnScrollAnimation'

class Enquire extends React.Component {

    componentDidMount(){
        this.props.fetchProduct(this.props.match.params.productSearchName)
    }

    renderPageContent(){
        return(
            <OnScrollAnimation>
                <SmallHeading heading="Enquire Now" />
                
                <Section className="bc_product-item-section">
                    <Container>
                        <Row style={{
                            justifyContent: 'center'
                        }}>
                            {
                                this.props.product && (
                                    <Grid col="6" className="bc_item bc_collection-item">
                                        <Link to={`/${this.props.product.brand.search_name}/${this.props.product.category.search_name}`}>
                                            <img src={`${BASE_URL}${this.props.product.image}`} alt=""  className="bc_image" />
                                            <h3>
                                                {this.props.product.title}
                                                <span>By: {this.props.product.brand.title}</span>
                                            </h3>
                                        </Link>
                                    </Grid>
                                )
                            }
                            
                        </Row>
                    </Container>
                </Section>

                <MakeAnEnquiry product={this.props.product} />
            </OnScrollAnimation>
        )
    }
    render() {
        return(
            <React.Fragment>

                <Helmet>
                    <title>Bathroom Collective | Enquire</title>
                    <meta name="robots" content="noindex, nofollow" />
                    <link rel="canonical" href={window.location} />
                </Helmet>
                
                <Nav>{this.renderPageContent()}</Nav>
                
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        product: state.productState[ownProps.match.params.productSearchName]
    }
}

export default connect(mapStateToProps, {
    fetchProduct
})(Enquire)
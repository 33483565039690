import React from 'react'
import {
    Section,
    Container,
    Row,
    Grid
} from '../Grid'
import AddToCartButton from '../Buttons/AddToCartButton'
import ButtonWhite from '../Buttons/ButtonWhite'
import Button from '../Buttons/Button'
import wishlistIcon from '../../assets/img/heart.svg'
import ProductImageCarousel from '../SlickSlider/ProductImageCarousel'
import { BASE_URL } from '../../paths'

class DoubleGridSectionProduct extends React.Component {

    renderImages(){
        return this.props.gallery.map((item, index) => {
            return (
                <figure>
                    <img src={BASE_URL + item.image_name} alt="" className="bc_image" />
                </figure>
            )
        })
    }

    renderMedia(){
        if(this.props.video){
            return (
                <video autoPlay muted loop>
                    <source src={this.props.video} type="video/mp4" />
                </video>
            )
        } else if(this.props.gallery){
            return(
                <ProductImageCarousel>
                    <figure>
                        <img src={this.props.img} alt="" className="bc_image"  />
                    </figure>
                    {this.renderImages()}
                </ProductImageCarousel>
            )
        } else {
            return (
                <figure>
                    <img src={this.props.img} alt=""  className="bc_image" />
                </figure>
            )
        }
    }

    render() {
        return(
            <Section className={`bc_double-grid-section ${this.props.order > 0 ? 'bc_swopped' : ''} ${this.props.className ? this.props.className : ''}`}>
                <Container>
                    <Row>
                        <Grid col="2-5" className="bc_scale">
                            {this.renderMedia()}
                        </Grid>
                        <Grid col="3-5">
                            <div className="bc_banner-text">
                                <div className="bc_content">
                                    <h2 className="themegold bold">{this.props.heading}</h2>
                                    <p className="white">{this.props.description}</p>

                                    {
                                        this.props.price && 
                                        <h2 className="white" style={{fontWeight: '300'}}>R {parseInt(this.props.price).toFixed(2)}</h2>
                                    }

                                    <div className="bc_buttons-group">
                                        {
                                            this.props.searchName && (
                                                <Button 
                                                    text="Enquire"
                                                    url={`/enquire/${this.props.searchName}`}
                                                />
                                            )
                                        }
                                        
                                        <ButtonWhite 
                                            text={`Add To My Collection `}
                                            onClick={this.props.addToCollection}
                                            icon={wishlistIcon}
                                        />

                                        {
                                            this.props.addToCart && (
                                                <AddToCartButton 
                                                    text="Add To Cart"
                                                    onClick={this.props.addToCart}
                                                />
                                            )
                                        }

                                       
                                    </div>

                                    
                                </div>
                            </div>
                        </Grid>
                    </Row>
                </Container>
            </Section>
        )
    }
}
export default DoubleGridSectionProduct
import React from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../../components/Nav/Nav'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import SmallHeading from '../../components/Content/SmallHeading'
import OnScrollAnimation from '../../components/Animations/OnScrollAnimation'
import {
    Section,
    Container,
    Row,
    Grid
} from '../../components/Grid'
import Input from '../../components/Forms/Input'
import Button from '../../components/Buttons/Button'
import {
    passwordFind,
    passwordReset
} from '../../actions'
import Loader from '../../components/ui/Loader'

class PasswordReset extends React.Component {

    state = {
        loader: false
    }

    componentDidMount(){
        this.props.passwordFind(this.props.match.params.resetToken)
    }

    componentWillUnmount(){
        this.setState({
            loader: false
        })
    }

    onSubmit = formValues => {        

        this.setState({
            loader: true
        })

        this.props.passwordReset(formValues, this.props.find.success.email, this.props.find.success.token)

        setTimeout(() => {
            this.setState({
                loader: false
            })
        }, 3000);
    }

    renderForm = () => { 

        if(this.props.find.error){
            return (
                <>
                    <Grid>
                        <h2>
                            Sorry but unfortunately we couldn't find your email address.
                        </h2>
                    </Grid>
                </>
            )
        } else if (this.props.find.success){

            return (
                <>
                    <Grid>
                        <h2>
                            Welcome Back! Please Enter Your New Password.
                        </h2>
                    </Grid>
                    <Grid col="12">
                        <Input name="password" type="password" label="Enter Password" required />
                    </Grid>

                    <Grid col="12">
                        <Input name="c_password" type="password" label="Confirm Password" required />
                    </Grid>
                    
                    <Grid col="12" className="bc_textAlign-center">
                        <input className="bc_form-submit" type="submit" value="Submit" />
                    </Grid>
                </>
            )
        }
    }

    renderPageContent(){
        return (
            <OnScrollAnimation>
                <SmallHeading heading="Password Reset" />
                <Section className="bc_form-section">
                    <Container className="bc_small">
                        <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                            <Row>
                                {
                                    this.props.resetState.error && <p className="bc_form-error-msg">Password reset failed. Please make sure you have confirmed your password correctly.</p>
                                }
                                {this.renderForm()}            
                            </Row>
                        </form>
                    </Container>
                </Section>
                <Section className="bc_form-section">
                    <Container className="bc_small">
                        <Row>
                        
                            <Grid className="bc_textAlign-center">
                                <h2>New Customer?</h2>
                                <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
                                <Button text="Signup" url="/register" />
                            </Grid>                            
                        </Row>
                    </Container>
                </Section>
            </OnScrollAnimation>
        )
    }

    render(){
        console.log(this.props.resetState.error)
        return(
            <React.Fragment>

                <Helmet>
                    <title>Bathroom Collective | Password Reset</title>
                    <meta name="robots" content="noindex, nofollow" />
                    <link rel="canonical" href={window.location} />
                </Helmet>

                { this.state.loader && <Loader /> }               

                <Nav>{this.renderPageContent()}</Nav>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        find: state.passwordFindState,
        resetState: state.passwordResetState
    }
}

const formWrapped = reduxForm({
    form: 'ResetPassword'
})(PasswordReset)

export default connect(mapStateToProps, {
    passwordFind,
    passwordReset
})(formWrapped)
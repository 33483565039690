import {
    FETCH_BROCHURES
} from '../actions/types'

export default (state = {}, action) => {
    switch (action.type){
        case FETCH_BROCHURES:
            return action.payload
        default:
            return state
    }
}
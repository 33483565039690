import React from 'react'
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import nextArrowIcon from '../../assets/img/Arrow-Right.svg'

class LongCarousel extends React.Component {

    next = () => {
        this.slider.slickNext();
    }

    render() {

        const settings = {
            infinite: true,
            speed: 750,
            slidesToShow: 5,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 10000,
            easing: 'cubic-bezier(0.85, 0, 0.15, 1)',
            pauseOnHover: false,
            dots: false,
            responsive: [
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 1,
                        dots: false,
                        arrows: false,
                        centerMode: true
                    }
                }
            ],
            dots: false,
        }

        return (
            <div className="bc_carousel">
                <Slider ref={c => (this.slider = c)} {...settings}>
                    {this.props.children}
                </Slider>                
                <button className="bc_arrow-next" onClick={this.next}>
                    <img src={nextArrowIcon} alt="" />
                </button>   
                <div className="clearfix"></div>             
            </div>
        )
    }
} 

export default LongCarousel
import React from 'react'
import LongCarousel from '../../components/SlickSlider/LongCarousel'
import {
    Section,
    Container,
} from '../../components/Grid'
import SectionHeading from '../../components/Content/SectionHeading.js'
import { fetchLocations } from '../../actions'
import { connect } from 'react-redux'
import { BASE_URL } from '../../paths'
import { Link } from 'react-router-dom'
import ImageScaleOnHover from '../../components/Animations/ImageScaleOnHover'

import LazyLoad from 'react-lazyload';

class StoreLocations extends React.Component {

    componentDidMount(){
        this.props.fetchLocations()
    }

    renderList(){
        return this.props.locations.map((item, index) => {
            return(
                <div className="bc_item bc_long-carousel-item bc_storelocations-carousel" key={index}>
                    <Link to={`/contact#bc_${item.postal_adrress}`}>
                        <ImageScaleOnHover>
                            {
                                index > 5 && (
                                    <LazyLoad>
                                        <figure>
                                            <img className="bc_image" src={`${BASE_URL}${item.image}`} alt="" />
                                        </figure>
                                    </LazyLoad>
                                )
                            }
                            {
                                index <= 5 && (
                                    <figure>
                                        <img className="bc_image" src={`${BASE_URL}${item.image}`} alt="" />
                                    </figure>
                                )
                            }
                            
                        </ImageScaleOnHover>
                        
                        <div className="bc_bottom-title">
                            <h4>{item.title}</h4>
                        </div>
                    </Link>                    
                </div>
            )
        })
    }

    render(){
        return(
            <Section className="bc_slider-section">
                <Container>
                    <SectionHeading text="Store Locations" className="bc_less-height" />
                    {
                        this.props.locations &&
                        <LongCarousel>
                            {this.renderList()}
                        </LongCarousel>
                    }                    
                </Container>
            </Section>
        )
    }
}

const mapStateToProps = state => {
    return {
        locations: state.locationsState.locations
    }
}

export default connect(mapStateToProps, {
    fetchLocations
})(StoreLocations)
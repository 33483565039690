import React from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../../components/Nav/Nav'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { 
    fetchBrandProducts,
    fetchBrands
} from '../../actions'
import {
    Section,
    Container,
    Row,
    Grid
} from '../../components/Grid'
import TopBanner from '../../components/Banner/TopBanner'
import OnScrollAnimation from  '../../components/Animations/OnScrollAnimation.js'
import { BASE_URL } from '../../paths'
import Pagination from '../../components/Pagination/Pagination'
import Filter from '../../components/Filters/Filter'
import ImageScaleOnHover from '../../components/Animations/ImageScaleOnHover'

class BrandProducts extends React.Component {

    componentDidMount(){
        this.props.fetchBrandProducts(this.props.match.params.searchName)
        if(!this.props.brand){
            this.props.fetchBrands()
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.match.params.searchName !== this.props.match.params.searchName){
            this.props.fetchBrandProducts(this.props.match.params.searchName)
        }
    }

    renderList(){
        return this.props.data.map((item, index) => {
            return(
                <Grid col="4" key={index} className="bc_item">
                    <Link to={`/${item.brand.search_name}/${item.category.search_name}`}>
                        <ImageScaleOnHover>
                            <figure>
                                <img src={`${BASE_URL}${item.image}`} alt="" className="bc_image" />
                            </figure>
                        </ImageScaleOnHover>
                        
                        <h4>
                            <div><span style={{textTransform: 'upperCase'}}>{item.brand.title}</span> {item.title}</div>
                        </h4>
                    </Link>
                </Grid>
            )
        })
    }

    renderPageContent() {
        return(
            <React.Fragment>

                <TopBanner 
                    heading={this.props.brand ? this.props.brand.description : ''}
                    subHeading={this.props.brand ? `${this.props.brand.title} - Products` : ''} 
                    logo={this.props.brand ? `${BASE_URL}${this.props.brand.logo_path}` : ''}
                    img={this.props.brand ? `${BASE_URL}${this.props.brand.banner}` : ''}
                />

                <OnScrollAnimation>
                    <Section className="bc_product-item-section">
                        <Filter />
                        <Container>
                            <Row>
                                {this.props.data && this.renderList()}
                            </Row>
                        </Container>
                    </Section>
                </OnScrollAnimation>

            </React.Fragment>
        )
    }

    renderMetaDescription = () => {
        return this.props.brand.meta_description ? this.props.brand.meta_description : this.props.brand.description
    }

    render() {

        const ldJson = {
            "@context": "https://schema.org/", 
            "@type": "BreadcrumbList", 
            "itemListElement": [{
              "@type": "ListItem", 
              "position": 1, 
              "name": "Home",
              "item": "https://bathroomcollective.co.za"  
            },{
              "@type": "ListItem", 
              "position": 2, 
              "name": this.props.brand ? this.props.brand.title : '',
              "item": "https://bathroomcollective.co.za/" + this.props.match.params.searchName  
            },{
              "@type": "ListItem", 
              "position": 3, 
              "name": "Products",
              "item": window.location.href
            }]
        }
        
        return(
            <React.Fragment>

                <Helmet>
                    <title>
                    {this.props.brand ? this.props.brand.title + ' - ' : ''}Bathroom Collective                        
                    </title>
                    {
                        this.props.brand && (
                            <meta name="description" content={this.renderMetaDescription()} />
                        )
                    }
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
                    <link rel="canonical" href={window.location} />
                </Helmet>

                <Nav>{this.renderPageContent()}</Nav>        

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        data: state.brandState[ownProps.match.params.searchName],
        brand: state.brandsState[ownProps.match.params.searchName]
    }
}

export default connect(mapStateToProps, { 
    fetchBrandProducts,
    fetchBrands
})(BrandProducts)
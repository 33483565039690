import React from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../../components/Nav/Nav'
import TopBanner from '../../components/Banner/TopBanner'
import DoubleGridSection from '../../components/Content/DoubleGridSection'
import ProductBrandCarousel from '../Products/ProductBrandCarousel'
import BrochureCarousel from '../Brochures/BrochureCarousel'
import OnScrollAnimation from  '../../components/Animations/OnScrollAnimation.js'
import { connect } from 'react-redux'
import { 
    fetchBrand,
    fetchBrandCategories 
} from '../../actions'
import { BASE_URL } from '../../paths'

class Brands extends React.Component {

    componentDidMount(){
        this.props.fetchBrand(this.props.match.params.searchName)
        this.props.fetchBrandCategories(this.props.match.params.searchName)
    }

    renderPageContent(){        

        return (
            <React.Fragment>
                <TopBanner 
                    img={`${BASE_URL}${this.props.brandData.banner}`}
                    heading={this.props.brandData.description} 
                    subHeading={this.props.brandData.title} 
                    logo={`${BASE_URL}${this.props.brandData.logo_path}`}
                    victoriaAlbert={this.props.match.params.searchName === 'victoria-albert' ? true : false}
                />

                {
                    this.props.brandCategories &&
                    <ProductBrandCarousel 
                        data={this.props.brandCategories} 
                        title="Products" 
                        searchName={this.props.brandData.search_name}
                        className="bc_section-product-brand-carousel"
                    /> 
                }
                 
                {
                    this.props.brandData.section &&
                    <OnScrollAnimation>
                        <DoubleGridSection
                            img= {`${BASE_URL}${this.props.brandData.section.image}`}
                            heading={this.props.brandData.section.description} 
                            subHeading={this.props.brandData.section.title} 
                            buttonText={this.props.brandData.section.button_text}
                            buttonLink={this.props.brandData.section.link}
                            order="0"
                        />
                    </OnScrollAnimation>
                }
                   
                <OnScrollAnimation>
                    <BrochureCarousel 
                        brandId={this.props.brandData.id} 
                        brandSearchName={this.props.brandData.search_name} 
                    /> 
                </OnScrollAnimation>
            </React.Fragment>
        )
    }

    render() {
        if(!this.props.brandData){
            return <></>
        }

        const ldJson = {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": this.props.brandData.title,
            "image": BASE_URL + this.props.brandData.image,
            "@id": BASE_URL + this.props.brandData.logo_path,
            "url": window.location.href,
            "telephone": "",
            "address": [
                {
                    "@type": "PostalAddress",
                    "streetAddress": "44 Clarendon Rd, Gants Plaza, Cape Town, 7140",
                    "addressLocality": "Somerset West",
                    "postalCode": "7140",
                    "addressCountry": "ZA"
                },
                {
                    "@type": "PostalAddress",
                    "streetAddress": "Unit 1, Business Park Adam Tas, Adam Tas Rd, Stellenbosch, 7599",
                    "addressLocality": "Stellenbosch",
                    "postalCode": "7599",
                    "addressCountry": "ZA"
                }  
            ]  
          }

        return(
            <React.Fragment>

                <Helmet>
                    <title>{this.props.brandData.title} - Bathroom Collective</title>
                    <meta name="description" content={this.props.brandData.meta_description ? this.props.brandData.meta_description : this.props.brandData.description} />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                    <link rel="canonical" href={window.location} />
                </Helmet>

                <Nav>{this.renderPageContent()}</Nav>        

            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        brandData: state.brandsState[ownProps.match.params.searchName],
        brandCategories: state.brandCategoryState[ownProps.match.params.searchName]
    }
}

export default connect(mapStateToProps, {
    fetchBrand,
    fetchBrandCategories
})(Brands)
import {
    FETCH_BUNDLE
} from '../actions/types'

export default (state = {}, action) => {
    switch(action.type) {
        case FETCH_BUNDLE:
            return {
                ...state,
                [action.payload.search_name]: action.payload
            }
        default:
            return state
    }
}
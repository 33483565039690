import React from 'react'
import {
    Container,
    Row,
    Grid
} from '../../components/Grid'

class Pagination extends React.Component {

    renderPagination = data => {
        const pagination = []
        for(let i = 1;i <= data.last_page; i++ ){
            pagination.push(
                <button 
                    key={i} 
                    onClick={this.props.onClick} 
                    value={i}
                    className={`${data.current_page === i ? 'active' : ''}`}
                >
                    {i}
                </button>
            )
        }
        return pagination
    }

    render(){

        const data = this.props.data
        
        if(data.per_page < data.total){
            return (
                <Container className="bc_pagination-container">
                    <Row>
                        <Grid>
                            {this.renderPagination(data)}
                        </Grid>
                    </Row>
                </Container>
            )
        }
        return <></>
    }    
}

export default Pagination
import React from 'react'
import { Link } from 'react-router-dom'

class SocialNavLink extends React.Component {
	constructor(props){
		super(props)
		this.state = {
			href: this.props.href
		}
	}
	render(){
		return(
			<a href={this.props.href} target="_blank"  rel="noopener noreferrer">
                <img src={this.props.src} alt={this.props.alt ? this.props.alt : ''} width="30" height="30" />
            </a>
		)
	}
}

export default SocialNavLink
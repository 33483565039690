import React from 'react'
import { reduxForm } from 'redux-form'
import {
    Section,
    Container,
    Row,
    Grid
} from '../../components/Grid'
import Input from '../../components/Forms/InputWithLabel'
import { validateEmail, validatePhone } from '../../helpers'
import { signOut } from '../../actions'
import { connect } from 'react-redux'

class AuthForm extends React.Component {

    logout = () => {
        this.props.signOut(this.props.auth.userToken)
    }

    onSubmit = formValues => {
        this.props.onSubmit(formValues)
    }

    render() {

        return(
            <Section className="bc_form-section">
                <Container className="bc_small">
                    <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                        <Row>                                
                            <Grid>
                                <h2>{this.props.heading}</h2>
                                {
                                    this.props.update.errors && <p className="bc_form-error-msg">Update Failed. Please make sure you have confirmed your password correctly.</p>
                                }
                                {
                                    this.props.register.errors && <p className="bc_form-error-msg">Registration Failed. Please make sure you have confirmed your password correctly.</p>
                                }
                            </Grid>
                            <Grid col="6">
                                <Input name="firstname" type="text" label="First Name" />
                            </Grid>
                            <Grid col="6">
                                <Input name="lastname" type="text" label="Last Name" />
                            </Grid>
                            <Grid col="6">
                                <Input name="email" type="email" label="Email" />
                            </Grid>
                            <Grid col="6">
                                <Input name="phone" type="text" label="Phone" />
                            </Grid>
                            <Grid col="6">
                                <Input name="password" type="password" label="Password" />
                            </Grid>
                            <Grid col="6">
                                <Input name="c_password" type="password" label="Confirm password" />
                            </Grid>                                    
                            <Grid className="bc_textAlign-center">
                                <input className="bc_form-submit" type="submit" value={this.props.initialValues ? 'Update Profile' : 'Signup'} />
                            </Grid>      
                                            
                        </Row>
                    </form>
                    {
                        this.props.auth.isSignedIn && (
                        <Row>
                            <Grid className="bc_textAlign-center">
                                <button className="bc_button_blue" onClick={this.logout}>Logout</button>
                            </Grid>
                        </Row>
                        )
                    }
                    
                </Container>
            </Section>
        )
    }
}

const validate = formValues => {
    const errors = {}

    if(!formValues.firstname){
        errors.firstname = "You must enter a first name"
    }

    if(!formValues.lastname){
        errors.lastname = "You must enter a last name"
    }

    if(!formValues.email){
        errors.email = "You must enter an email"
    } else if(formValues.email && !validateEmail(formValues.email)){
        errors.email = "You must enter a valid email"
    }

    if(!formValues.phone){
        errors.phone = "You must enter a phone number"
    } else if(formValues.phone && !validatePhone(formValues.phone)){
        errors.phone = "You must enter a valid phone number"
    }

    if(!formValues.lastname){
        errors.lastname = "You must enter a first name"
    }

    if(!formValues.password){
        errors.password = "You must enter a password"
    }

    if(!formValues.c_password){
        errors.c_password = "You must confirm your password"
    }

    return errors
}

const mapStateToProps = state => {
    return {
        update: state.profileState,
        register: state.registerState,
        auth: state.authState
    }
}

export default reduxForm({
    form: 'authForm',
    validate        
})(connect(mapStateToProps, {
    signOut,
})(AuthForm))
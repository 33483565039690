import { 
    UPDATE_PROFILE,
    UPDATE_PROFILE_ERROR
} from '../actions/types'

const INITIAL_STATE = {
    success: null,
    message: null,
    errors: null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type){
        case UPDATE_PROFILE:
            return {
                ...state,
                success: true,
                message: action.payload,
                errors: null
            }
        case UPDATE_PROFILE_ERROR:
            return {
                ...state,
                success: null,
                message: action.payload,
                errors: true
            }
            
        default:
            return state
    }
}
import React from 'react'
import {
    Section,
    Container,
    Row,
    Grid
} from '../Grid'
import TopBannerText from '../Content/TopBannerText'

class TopBanner extends React.Component {
    render() {
        const style = {
            backgroundImage: `url(${this.props.img})`
        }
        return(
            <Section className="bc_top-banner" style={style}>
                <Container className="valign-center_halign-left">
                    <Row>
                        <Grid col="12">
                            <TopBannerText 
                                props={this.props}
                                victoriaAlbert={this.props.victoriaAlbert ? true : false}
                            />
                        </Grid>
                    </Row>
                </Container>
            </Section>
        )
    }
}

export default TopBanner
import React from 'react'

class AddToCartButton extends React.Component {
	render(){
		return (
			<button 
                className={`bc_button_gold bc_button-addtocart ${this.props.className ? this.props.className : ''}`} 
                onClick={this.props.onClick}
			> 	
                {this.props.text}
			</button>
		)
	}
}

export default AddToCartButton
import React from 'react'
import {
    Section,
    Container,
    Row,
    Grid
} from '../Grid'
import { Link } from 'react-router-dom'

class SmallHeading extends React.Component {
    render() {
        return(
            <Section className="bc_small-heading-section">
                <Container>
                    <Row>
                        <Grid col="6">

                            <h3 className="themegold">{this.props.heading}</h3>
                        </Grid>
                        <Grid col="6">

                            <p>Any questions? <Link to="/contact" className="themegold">Contact Us</Link></p>
                        </Grid>
                    </Row>
                </Container>
            </Section>
        )
    }
}
export default SmallHeading
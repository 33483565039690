import React from 'react'

class ScrollToTopArrow extends React.Component{

    scrollToTop = () => {
        window.scrollTo(0, 0)
    }

    render(){
        return(
            <button className="bc_scroll-to-top" onClick={this.scrollToTop} title="Scroll To Top">
                <span></span>
            </button>
        )
    }
}

export default ScrollToTopArrow
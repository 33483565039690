import React from 'react'

class ImageScaleOnHover extends React.Component {
    render(){
        return(
            <div className="bc_scale">
                {this.props.children}
            </div>
        )
    }
}

export default ImageScaleOnHover
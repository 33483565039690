import {
    FETCH_BUNDLES
} from '../actions/types'

export default (state = {}, action) => {
    switch(action.type) {
        case FETCH_BUNDLES:
            return action.payload
        default:
            return state
    }
}
import React from 'react'
import history from '../../history'

class Button extends React.Component {

	onClick = () => {
		history.push(this.props.url)
	}
	render(){
		return (
			<button 
				className={`bc_button_gold ${this.props.className ? this.props.className : ''}`} 
				onClick={this.onClick}
			> 	
				{this.props.text}
			</button>
		)
	}
}

export default Button
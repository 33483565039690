import React from 'react'
import Button from '../Buttons/Button'
import OnScrollAnimation from  '../Animations/OnScrollAnimation.js'
import {Link} from 'react-router-dom'

class TopBannerText extends React.Component {

    renderSubheading(){
        if(this.props.props.back){
            return(
                <Link to={this.props.props.back}>
                    <h3 className="themegold">{this.props.props.subHeading}</h3>
                </Link>
            )
        } else {
            return <h3 className="themegold">{this.props.props.subHeading}</h3>
        }
    }

    render() {
        return(
            <OnScrollAnimation>
                <div className="bc_top-banner-text">
                    <div className="bc_content">
                        {this.props.props.subHeading && this.renderSubheading()}
                        
                        <h1 className="white">{this.props.props.heading}</h1>
                        {this.props.victoriaAlbert && <a href="https://www.cpandb.co.za/news/colour_inspiration_for_the_bathroom" className='_button' target="_blank" rel="noreferrer">Design Inspiration</a>}
                        
                        {
                            this.props.props.buttonText && 
                            <Button 
                                text={this.props.props.buttonText} 
                                url={this.props.props.buttonLink}
                            />
                        }
                        {
                            this.props.props.logo && 
                            <img className="bc_brand-logo" src={this.props.props.logo} alt={this.props.props.heading} />
                        }
                    </div>
                </div>
            </OnScrollAnimation>
            
        )
    }
}

export default TopBannerText
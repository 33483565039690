import React from 'react'
import { Helmet } from 'react-helmet'
import Nav from '../../components/Nav/Nav'
import TopBanner from '../../components/Banner/TopBanner'
import DoubleGridSectionProduct from '../../components/Content/DoubleGridSectionProduct'
import { connect } from 'react-redux'
import { 
    fetchCategory,
    fetchBrand,
    addItem,
    addCollectionItem,
    fetchCategoriesWithOneProduct
} from '../../actions'
import { BASE_URL } from '../../paths'
import Popup from '../../components/Popups/Popup'
import Button from '../../components/Buttons/Button'
import ButtonWhite from '../../components/Buttons/ButtonWhite'
import closeImg from '../../assets/img/iconmonstr-x-mark-9-240.png'
import Loader from '../../components/ui/Loader'

class BrandCategory extends React.Component {

    state = {
        popupCart: false,
        popupCollection: false,
        loader: false
    }

    componentDidMount(){
        this.props.fetchCategory(this.props.match.params.categorySearchName)
        this.props.fetchBrand(this.props.match.params.brandSearchName)
        this.props.fetchCategoriesWithOneProduct() 
    }

    componentWillUnmount(){
        this.setState({
            loader: false
        })
    }

    addToCart = search_name => {
        this.setState({
            loader: true
        })

        setTimeout(() => {
            if(this.props.addItem(search_name)){
                this.setState({
                    popupCart:true,
                    loader: false
                })
            } 
        }, 1000);  
    }

    addToCollection = search_name => {
        this.setState({
            loader: true
        })

        setTimeout(() => {
            if(this.props.addCollectionItem(search_name)){
                this.setState({
                    popupCollection:true,
                    loader: false
                })
            }
        }, 1000);
                
    }

    closePopup = () => {
        this.setState({
            popupCart:false,
            popupCollection:false
        })
    }

    renderList(){
        let i = 0
        return this.props.data.map((item, index) => {
            if(item.brand){
                if(item.brand.search_name === this.props.match.params.brandSearchName){
                    i++
                    return(
                        <DoubleGridSectionProduct
                            img={`${BASE_URL}${item.image}`}
                            heading={item.title}
                            description={item.description}
                            order={i%2 === 0 ? '1' : '0'} 
                            key={index}
                            searchName={item.search_name}
                            // addToCart={() => this.addToCart(item.search_name)}
                            addToCollection={() => this.addToCollection(item.search_name)}
                            // price={item.price}
                            video={item.video ? BASE_URL + item.video : null}
                            gallery={item.images.length > 0 ? item.images : null}
                        />
                    )
                }
            }            
        })
    }

    renderPageContent(){
        return (
            <React.Fragment>
                <TopBanner 
                    img={`${BASE_URL}${this.props.brand.banner}`}
                    heading={this.props.brand.description} 
                    subHeading={`${this.props.brand.title} - ${this.props.category.title}`}
                    logo={`${BASE_URL}${this.props.brand.logo_path}`}
                    back={`/${this.props.match.params.brandSearchName}`}
                /> 
                {this.renderList()}
                

            </React.Fragment>
        )
    }

    render() {

        if(!this.props.data){
            return <></>
        }

        if(!this.props.brand){
            return <></>
        }

        if(!this.props.category){
            return <></>
        }

        const ldJson = {
            "@context": "https://schema.org/", 
            "@type": "BreadcrumbList", 
            "itemListElement": [{
              "@type": "ListItem", 
              "position": 1, 
              "name": "Home",
              "item": "https://bathroomcollective.co.za"  
            },{
              "@type": "ListItem", 
              "position": 2, 
              "name": this.props.brand.title,
              "item": "https://bathroomcollective.co.za/" + this.props.match.params.brandSearchName  
            },{
              "@type": "ListItem", 
              "position": 3, 
              "name": this.props.category.title,
              "item": window.location.href  
            }]
          }
        
        return(
            <React.Fragment>
                <Helmet>
                    <title> {this.props.category.title} - {this.props.brand.title} - Bathroom Collective</title>
                    <meta name="description" content={this.props.brand.meta_description ? this.props.brand.meta_description : this.props.brand.description} />
                    <script type="application/ld+json">
                        {JSON.stringify(ldJson)}
                    </script>
                    <link rel="canonical" href={window.location} />
                </Helmet>

                { this.state.loader && <Loader /> }

                <Nav>
                    {this.renderPageContent()}
                </Nav>

                { this.state.popupCart &&
                    <Popup>
                        <button className="bc_popup-close-btn" onClick={this.closePopup}>
                            <img src={closeImg} alt="" />
                        </button>
                        <h2>Item added to cart</h2>
                        <p>Lorem ipsum dolor sit amet</p>
                        <div className="bc_popup-flex">
                            <Button
                                text="View Cart"
                                url="/cart"
                            />
                            <ButtonWhite
                                text="Continue Shopping"
                                onClick={this.closePopup}
                            />
                        </div>
                    </Popup>
                }

                { this.state.popupCollection &&
                    <Popup>
                        <button className="bc_popup-close-btn" onClick={this.closePopup}>
                            <img src={closeImg} alt="" />
                        </button>
                        <h2>Item added to collection</h2>
                        <div className="bc_popup-flex">
                            <Button
                                text="View Collection"
                                url="/collections"
                            />
                        </div>
                    </Popup>
                }
                
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        data: state.categoryState[ownProps.match.params.categorySearchName],
        brand: state.brandsState[ownProps.match.params.brandSearchName],
        category: state.categoriesState[ownProps.match.params.categorySearchName]
    }
}

export default connect(mapStateToProps, {
    fetchCategory,
    addItem,
    addCollectionItem,
    fetchBrand,
    fetchCategoriesWithOneProduct
})(BrandCategory)
import {
    FETCH_BRAND_CATEGORIES
} from '../actions/types'

export default (state = {}, action) => {
    switch(action.type){
        case FETCH_BRAND_CATEGORIES:
            return {
                ...state,
                [action.payload.brandSearchName]: action.payload.data
            }
        default:
            return state
    }
}